@each $provider,
$color in $providers {
  tr {
    &.#{$provider} td:first-child {
      border-left: solid 4px $color;
      span.MuiButtonBase-root.MuiIconButton-root.MuiCheckbox-root.mui-checkbox.Mui-checked .MuiIconButton-label svg.MuiSvgIcon-root {
        color: $color;
      }
    }
  }
}

tr.unknown {
  td:first-child {
    border-left: solid 4px white;
    span.MuiButtonBase-root.MuiIconButton-root.MuiCheckbox-root.mui-checkbox.Mui-checked .MuiIconButton-label svg.MuiSvgIcon-root {
      color: #A4A4DE;
    }
  }
}
