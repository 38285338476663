@mixin max-screen($max) {
  @media (max-width: $max) {
    @content;
  }
}

@mixin min-screen($min) {
  @media (min-width: $min) {
    @content;
  }
}

@mixin min-height($min) {
  @media (min-height: $min) {
    @content;
  }
}

@mixin max-height($min) {
  @media (max-height: $min) {
    @content;
  }
}

@mixin min-max-screen($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin not-grid(){
  @supports not (display: grid) {
    @content;
  }
}