.lovingly-loading {
    width: 75px;
    height: 75px;
    position: absolute;
    top: 50%;
    left: calc(50% - 37.5px);

    img {
        width: 75px;
        height: 75px;
    }
}