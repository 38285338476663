/*----------  Providers  ----------*/

// UFN
$ufn: hsl(359, 100%, 86%);
// FTD
$ftd: hsl(47, 100%, 52%);
// Teleflora;
$teleflora: hsl(340, 95%, 53%);
// Bloomnation
$bloomnation: hsl(183, 47%, 38%);
// FSN
$fsn: hsl(35, 100%, 53%);
// Floranext
$floranext: hsl(335, 68%, 68%);
// Bloomnet
$bloomnet: hsl(271, 56%, 32%);
// Media99
$media99: hsl(19, 89%, 55%);
// Websystems
$websystems: hsl(74, 70%, 75%);
// Custom
$custom: hsl(0, 0%, 67%);
// Domain for Sale
$domainsforsale: hsl(119, 100%, 50%);
// Epic Flowers
$epicflowers: hsl(181, 86%, 48%);
// PFS
$pfs: hsl(354, 100%, 50%);
// Yahoo Store
$yahoostore: hsl(297, 55%, 36%);
// Flower Local
$flowerslocal: hsl(88, 66%, 29%);
// RFK
$rfk: hsl(220, 62%, 32%);
// LAFS
$lafs: hsl(304, 82%, 52%);
// Squarespace
$squarespace: hsl(0, 0%, 0%);
// VistaPrint
$vistaprint: hsl(198, 74%, 52%);
// BigCommerce
$bigcommerce: hsl(190, 66%, 70%);
// Volusion
$volusion: hsl(200, 100%, 25%);
// Shopify
$shopify: hsl(81, 73%, 63%);
// FloristBoard
$floristboard: hsl(96, 62%, 14%);
// Webshop101
$webshop101: hsl(65, 100%, 75%);
// CanaFlora
$canafloral: hsl(5, 100%, 75%);
// YPF
$ypf: hsl(354, 100%, 90%);
//Unknown
$unknown: hsl(0, 0%, 100%);
// gravityfree
$gravityfree: #3a3c45;

// Loop over list of headings
// @each ( $heading : $size )
$providers: (ufn:$ufn, gravityfree:$gravityfree, websystems:$websystems, media99:$media99, bloomnet:$bloomnet, floranext:$floranext, fsn:$fsn, bloomnation:$bloomnation, teleflora:$teleflora, ftd:$ftd, rfk:$rfk, lafs:$lafs, squarespace:$squarespace, vistaprint:$vistaprint, bigcommerce:$bigcommerce, volusion:$volusion, flowerslocal:$flowerslocal, yahoostore:$yahoostore, pfs:$pfs, epicflowers:$epicflowers, shopify:$shopify, floristboard:$floristboard, webshop101:$webshop101, ypf:$ypf, unknown: $unknown);