.columns__container {
    height: 100%;
    display: flex;
    align-items: stretch;
}

.tags__container {
    height: 100%;
    display: flex;
    align-items: stretch;
}

.tags {
    &__left-column {
        grid-area: tagcontainer;
        overflow: auto;
        width: 509px;
        height: 100% ;
        display: flex;
        flex-flow: column;
        flex-shrink:0;
    }

    &__center-column {
        grid-area: contentlist;
        overflow: scroll;

        .left-column {
            height: 100% ;
        }
    }
}

.height-100 {
    height: 100%;
}

.left-column {
    overflow: scroll;
    width: 100%;
}

.right-column {
    width: 637px;
    height: 100%;
    // overflow: auto;
    flex-shrink: 0;
    border-left: solid 1px rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
    z-index: 0;
    display: flex;
    flex-flow: column;
}

.table-container-padding {
    padding: 20px;
}

.grid {
    display: grid;
}

.align-self-center {
    align-self: center;
}

.panel-padding {
    padding: 15px 6px;
}

.table-search-wrap {
    padding: 20px 23px;
    display: grid;
    grid-template-rows: 58px 1fr;

    &.single-table {
        grid-template-rows: auto !important;
    }

    &.double-table{
        grid-template-rows: 1fr 48px!important;
    }

    &__list {
        width: 100%;
        overflow: auto;
        border: solid 1px #EBEBEB;
        border-top: 0;
        background: white;

        th {
            padding: 7px 10px;
            height: 55px;
            border-left: solid 4px transparent;
        }

        td {
            padding: 14px 10px;
            white-space: nowrap;
        }

        &.domainsList {
            td {
                padding: 9px 10px;
            }
        }
    }
}

.table-search-wrap__list {
    .MuiTableRow-root {
        border-color: #EBEBEB;

        &.active,
        &.MuiTableRow-hover:hover {
            background-color: #F7FAFD;
        }
    }
}

.search {
    &__container {
        border: solid 1px #EBEBEB;
        border-radius: 4px 4px 0 0;
        background: white;
    }
}

div[role="tabpanel"] .MuiBox-root {
    padding: 0;
}

.capitalize {
    text-transform: capitalize;
}

span.MuiTypography-root.MuiFormControlLabel-label {
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0.01em;
    line-height: 48.4px;
    text-align: left;
    color: #424242;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #8BC34A;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 11px solid #FF1744;
}

.tab-100 {
    height: calc(100% - 60px);

    .MuiBox-root {
        height: calc(100% - 60px);
    }
}

.filters__radio-group {
    .MuiFormControlLabel-label {
        font-size: 14px !important;
        letter-spacing: 0.01em !important;
        line-height: 14.4px !important;
        color: #9e9e9e !important;
    }

    .MuiRadio-root {
        color: #9e9e9e !important;

        &.Mui-checked {
            color: #A4A4DE !important;
        }
    }
}

.hide {
    display: none;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.96);
    z-index: 2010;

    &__content-container {
        position: relative;
        width: 675px;
        min-height: 745px;
        margin: calc(50% - 36%) auto 0;
    }

    &__heading {
        font-size: 20px;
        color: rgba(0, 0, 0, .35);
        margin-bottom: 40px;
    }
}

.close-icon {
    color: #B2BECB;
    cursor: pointer;
    position: absolute;
    right: -50px;
    top: -100px;
    font-size: 41px !important;
}