.date-range-filter {
    &__container {
        display: flex;
        align-items: center;
    }

    &__button {
        appearance: none;
        border: 0;
        box-shadow: none;
        background: transparent;
        min-height: unset;
        min-width: unset;
        font-size: 12px;
        letter-spacing: 0.01em;
        line-height: 14.4px;
        text-align: left;
        color: #9e9e9e;
        cursor: pointer;

        &:hover,
        &.active {
            color: #0091EA;
            text-decoration: underline;
        }
    }
}