body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Muli', 'Helvetica', Arial, sans-serif;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.01em;
    line-height: 49px;
    text-align: left;
    color: rgba(33, 33, 33, .87);
    background: #F7FAFD;
}

html,
body,
#root,
main {
    height: 100%;
}

/*----------  Mini-overrides  ----------*/

.no-margin {
    margin: 0 !important;

    &-bottom {
        margin-bottom: 0 !important;
    }

    &-left {
        margin-left: 0 !important;
    }

    &-right {
        margin-right: 0 !important;
    }

    &-top {
        margin-top: 0 !important;
    }
}

.no-padding {
    padding: 0 !important;

    &-bottom {
        padding-bottom: 0 !important;
    }

    &-left {
        padding-left: 0 !important;
    }

    &-right {
        padding-right: 0 !important;
    }

    &-top {
        padding-top: 0 !important;
    }
}

/*----------  Scrollbars  ----------*/

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-button {
    width: 48px;
    height: 48px;
}

::-webkit-scrollbar-thumb {
    background: #b5b4b5;
    border: 0px none #686768;
    border-radius: 0px;
}

::-webkit-scrollbar-track {
    background: transparent;
    border: 0;
}

::-webkit-scrollbar-corner {
    background: transparent;
    display: none;
}

.small-header-table {
    thead tr th {
        padding: 5px 10px !important;
    }
}

nav {
    .active {
        opacity: 1;
    }

    a:not(.active) {
        opacity: .38;

        &:hover {
            opacity: 1;
        }
    }
}